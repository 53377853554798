import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { EmployeeAvatar } from '../../components/Avatars/EmployeeAvatar';
import { EmployeeLink } from '../../components/Links/EmployeeLink';
import { Employee } from '../../generated/graphql';

interface UnassignedEmployeesProps {
  employees: Employee[];
}

export const UnassignedEmployees = ({ employees }: UnassignedEmployeesProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  if (employees.length === 0) return null;

  const displayCount = showAll ? employees.length : Math.min(5, employees.length);
  const displayedEmployees = employees.slice(0, displayCount);

  return (
    <div className='flex h-50 mt-10 mb-10 justify-between'>
      <div className='flex-col w-full'>
        <div className='text-xl mb-10'>Employees without billable assignment</div>
        <div className='grid grid-cols-2 gap-x-5 gap-y-2 w-full content-center mr-8 text-sm'>
          {displayedEmployees.map((employee) => (
            <UnassignedEmployeeCard key={employee.id} employee={employee} />
          ))}
          {employees.length > 5 && (
            <ShowMoreButton
              totalCount={employees.length}
              showAll={showAll}
              onClick={() => setShowAll(!showAll)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const UnassignedEmployeeCard = ({ employee }: { employee: Employee }) => (
  <div className='flex border-2 border-gray-200 shadow p-4 rounded-lg min-h-[40px]'>
    <EmployeeAvatar employee={employee} />
    <div className='flex-col ml-3'>
      <EmployeeLink employee={employee} className='flex font-bold' />
      <div className='font-thin'>No allocation</div>
    </div>
  </div>
);

const ShowMoreButton = ({
  totalCount,
  showAll,
  onClick
}: {
  totalCount: number;
  showAll: boolean;
  onClick: () => void;
}) => (
  <div
    className='flex justify-between border-2 border-gray-200 shadow p-4 rounded-lg min-h-[40px] hover:bg-gray-200'
    onClick={onClick}
  >
    <div className='flex-col ml-3'>
      <div className='font-bold'>{totalCount} with out assignment</div>
      <div className='font-thin'>{showAll ? 'Collapse' : 'Show all'}</div>
    </div>
    {!showAll ? (
      <ChevronDownIcon className='text-black w-5 h-5 self-center' />
    ) : (
      <ChevronUpIcon className='text-black w-5 h-5 self-center' />
    )}
  </div>
);
