import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { EmployeeAvatar } from '../../components/Avatars/EmployeeAvatar';
import { EmployeeLink } from '../../components/Links/EmployeeLink';
import { ProjectLink } from '../../components/Links/ProjectLink';
import { Assignments, Employee } from '../../generated/graphql';
import { groupAssignmentsByEmployee } from '../../models/AssignmentTypes';

// Added common base card style to reduce repetitive styling
const baseCardStyle = 'flex border-2 shadow p-4 rounded-lg min-h-[40px]';

// Higher Order Component to wrap a component with card styling
const withCard = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P & { cardClass?: string; onClick?: () => void }) => {
    const { cardClass = '', onClick, ...restProps } = props;
    return (
      <div className={`${baseCardStyle} ${cardClass}`} onClick={onClick}>
        <Component {...(restProps as P)} />
      </div>
    );
  };
};

interface AssignmentGroupProps {
  assignments: Assignments[];
}

export const AssignmentGroup = ({ assignments }: AssignmentGroupProps) => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  const toggleExpand = (employeeId: string) => {
    setExpanded((prev) => ({
      ...prev,
      [employeeId]: !prev[employeeId]
    }));
  };

  return (
    <>
      {groupAssignmentsByEmployee(assignments).map((byEmployee) => {
        const multiple = byEmployee.assignments.length > 1;
        const isExpanded = expanded[byEmployee.employee.id];

        return (
          <>
            <AssignmentCard
              key={`emp-${byEmployee.employee.id}`}
              employeeAssignments={byEmployee}
              isExpanded={isExpanded}
              multiple={multiple}
              onToggle={() => multiple && toggleExpand(byEmployee.employee.id)}
            />

            {multiple &&
              isExpanded &&
              byEmployee.assignments.map((assignment) => (
                <DetailedAssignmentCard
                  key={assignment.project?.id}
                  assignment={assignment}
                  employee={byEmployee.employee}
                />
              ))}
          </>
        );
      })}
    </>
  );
};

// Inner component for AssignmentCard content (without the outer wrapper)
interface AssignmentCardContentProps {
  employeeAssignments: { employee: Employee; assignments: Assignments[] };
  multiple: boolean;
  isExpanded: boolean;
}

const AssignmentCardContent = ({
  employeeAssignments,
  multiple,
  isExpanded
}: AssignmentCardContentProps) => (
  <>
    <EmployeeAvatar employee={employeeAssignments.employee} />
    <div className='flex-col ml-3'>
      <EmployeeLink employee={employeeAssignments.employee} className='flex font-bold' />
      {multiple ? (
        <div className='flex font-thin'>{`${employeeAssignments.assignments.length} assignments are ending`}</div>
      ) : (
        <ProjectLink
          project={employeeAssignments.assignments[0].project!}
          className='flex font-thin'
        >
          <>
            {`${employeeAssignments.assignments[0].project?.name} (${employeeAssignments.assignments[0].project?.customer?.name})`}
          </>
        </ProjectLink>
      )}
    </div>
    {multiple &&
      (isExpanded ? (
        <BarsArrowUpIcon className='text-black w-5 h-5 self-center content-end ml-auto' />
      ) : (
        <BarsArrowDownIcon className='text-black w-5 h-5 self-center content-end ml-auto' />
      ))}
  </>
);

// Wrap AssignmentCardContent with card styling using the HOC
const WithCardAssignmentCard = withCard(AssignmentCardContent);

const AssignmentCard = ({
  employeeAssignments,
  isExpanded,
  multiple,
  onToggle
}: {
  employeeAssignments: { employee: Employee; assignments: Assignments[] };
  isExpanded: boolean;
  multiple: boolean;
  onToggle: () => void;
}) => {
  const cardClass = `${
    multiple && isExpanded ? 'border-blue-300 shadow-blue-100' : 'border-gray-200'
  } ${multiple ? 'hover:bg-gray-200' : ''}`;

  return (
    <WithCardAssignmentCard
      cardClass={cardClass}
      onClick={onToggle}
      employeeAssignments={employeeAssignments}
      multiple={multiple}
      isExpanded={isExpanded}
    />
  );
};

// Inner component for DetailedAssignmentCard content (without the outer wrapper)
interface DetailedAssignmentCardContentProps {
  assignment: Assignments;
  employee: Employee;
}

const DetailedAssignmentCardContent = ({
  assignment,
  employee
}: DetailedAssignmentCardContentProps) => (
  <>
    <EmployeeAvatar employee={employee} />
    <div className='flex-col ml-3'>
      <EmployeeLink employee={employee} className='flex font-bold' />
      <ProjectLink project={assignment.project!} className='flex font-thin'>
        <>{`${assignment.project?.name} (${assignment.project?.customer?.name})`}</>
      </ProjectLink>
    </div>
  </>
);

// Wrap DetailedAssignmentCardContent with card styling using the HOC
const WithCardDetailedAssignmentCard = withCard(DetailedAssignmentCardContent);

const DetailedAssignmentCard = ({
  assignment,
  employee
}: {
  assignment: Assignments;
  employee: Employee;
}) => {
  const cardClass = 'border-blue-300 shadow-blue-100';
  return (
    <WithCardDetailedAssignmentCard
      cardClass={cardClass}
      employee={employee}
      assignment={assignment}
    />
  );
};
