import { format, isSameMonth, parseISO } from 'date-fns';
import { Assignments, Employee } from '../../generated/graphql';
import { DateRange } from '../../utils/date';
import { monthName } from '../../utils/formatters';
import { AssignmentGroup } from './AssignmentGroup';

interface AssignmentTimelineProps {
  employees: Employee[];
  displayRange: DateRange;
}

export const AssignmentTimeline = ({ employees, displayRange }: AssignmentTimelineProps) => {
  const mapAssignmentsByEndDate = (
    employees: Employee[],
    date: Date
  ): Map<string, Assignments[]> => {
    return employees.reduce((assignmentMap, employee) => {
      employee.assignments
        .filter((assignment) => isSameMonth(parseISO(assignment.end_date), date))
        .forEach((assignment) => {
          const enrichedAssignment = {
            ...assignment,
            employee: employee
          };

          const existing = assignmentMap.get(assignment.end_date) || [];
          assignmentMap.set(assignment.end_date, [...existing, enrichedAssignment]);
        });

      return assignmentMap;
    }, new Map<string, Assignments[]>());
  };

  return (
    <>
      <div className='text-xl mb-10'>Billable assignments ending</div>
      <div className='text-gray-700 antialiased text-sm font-semibold relative ml-2'>
        <div className='sm:block w-[1px] bg-gray-300 absolute h-full text-black' />
        {[...displayRange].map((date: Date) => {
          const assignmentsByDate = mapAssignmentsByEndDate(employees, date);
          const sortedDates = [...assignmentsByDate.keys()].sort(
            (a, b) => parseISO(a).getTime() - parseISO(b).getTime()
          );

          return (
            <MonthSection
              key={`months-${monthName(date)}-${date.getFullYear()}`}
              date={date}
              sortedDates={sortedDates}
              assignmentsByDate={assignmentsByDate}
            />
          );
        })}
      </div>
    </>
  );
};

const MonthSection = ({
  date,
  sortedDates,
  assignmentsByDate
}: {
  date: Date;
  sortedDates: string[];
  assignmentsByDate: Map<string, Assignments[]>;
}) => (
  <div className='mt-6 sm:mt-0 sm:mb-20'>
    <div className='flex flex-col items-start'>
      <div className='flex w-full'>
        <div className='flex items-center justify-center self-start'>
          <div className='bg-gray-300 text-neutral-200 text-lg p-1 border-2 border-white font-thin rounded-full w-5 h-5 text-center -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 ' />
          <div className='ml-7'>{`${monthName(date)} ${date.getFullYear()}`}</div>
        </div>
      </div>

      {sortedDates.map((dateStr: string) => (
        <DaySection
          key={`days-${dateStr}`}
          date={dateStr}
          assignments={assignmentsByDate.get(dateStr) ?? []}
        />
      ))}
    </div>
  </div>
);

const DaySection = ({ date, assignments }: { date: string; assignments: Assignments[] }) => (
  <div className='flex w-full mt-6'>
    <div className='flex items-center justify-center self-start'>
      <div className='bg-gray-300 text-neutral-200 text-lg p-1 font-thin rounded-full w-2 h-2 text-center -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 ' />
      <div className='ml-10 flex-col justify-center pt-4'>
        <div className='text-xl'>{format(parseISO(date), 'dd')}</div>
        <div className='text-l uppercase'>{format(parseISO(date), 'MMM')}</div>
      </div>
    </div>

    <div className='grid grid-cols-2 gap-x-5 gap-y-2 w-full content-center ml-8'>
      <AssignmentGroup assignments={assignments} />
    </div>
  </div>
);
