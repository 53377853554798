import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Calendar } from './pages/Calendar';
import { Dashboard } from './pages/Dashboard';
import { People } from './pages/People';
import { Projects } from './pages/Projects/Projects';
import { createApolloClient } from './providers/ApolloClient';

import { Amplify } from 'aws-amplify';
import { CustomerPage } from './pages/Customers/CustomerDetails';
import CustomersPage from './pages/Customers/CustomersPage';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { NoSuperPowers } from './pages/NoSuperPowers';
import { PayrollPage } from './pages/Payroll/PayrollPage';
import { EmployeePage } from './pages/People/EmployeePage';
import { ProjectPage } from './pages/Projects/ProjectPage';
import { Resourcing } from './pages/Resourcing/Resourcing';
import { AuthProvider } from './providers/Auth';
import { MonthSelectionProvider } from './providers/MonthContext';
import SystemUi from './providers/SystemUi';
import DefinedRoutes from './routes/DefinedRoutes';
import { ProtectedLayout } from './routes/ProtectedLayout';

const aws_config = {
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_SRP_AUTH',
  oauth: {
    domain: process.env.REACT_APP_AWS_DOMAIN,
    scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT_URL,
    responseType: 'code'
  }
};

console.log('AWS', aws_config);

Amplify.configure(aws_config);

const useSentryRoutes = (process.env.REACT_APP_ENVIRONMENT || 'local') !== 'local';
const CustomRoutes = useSentryRoutes ? Sentry.withSentryReactRouterV6Routing(Routes) : Routes;

const App = () => {
  const [client] = useState(createApolloClient(process.env.REACT_APP_HASURA_TOKEN as string));
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <SystemUi>
            <MonthSelectionProvider>
              <CustomRoutes>
                <Route path={DefinedRoutes.LOGIN} element={<Login />} />
                <Route path={DefinedRoutes.NO_SUPER_POWERS} element={<NoSuperPowers />} />
                <Route path={DefinedRoutes.ROOT} element={<ProtectedLayout />}>
                  <Route index element={<Dashboard />} />
                  <Route path={DefinedRoutes.DASHBOARD} element={<Dashboard />} />
                  <Route path={DefinedRoutes.RESOURCING} element={<Resourcing />} />
                  <Route path={DefinedRoutes.PEOPLE} element={<People />} />
                  <Route path={DefinedRoutes.PROJECTS} element={<Projects />} />
                  <Route path={DefinedRoutes.PROJECT} element={<ProjectPage />} />
                  <Route path={DefinedRoutes.EMPLOYEE} element={<EmployeePage />} />
                  <Route path={DefinedRoutes.CALENDAR} element={<Calendar />} />
                  <Route path={DefinedRoutes.PAYROLL} element={<PayrollPage />} />
                  <Route path={DefinedRoutes.CUSTOMER} element={<CustomerPage />} />
                  <Route path={DefinedRoutes.CUSTOMERS} element={<CustomersPage />} />
                </Route>
                <Route path={DefinedRoutes.LOGOUT} element={<Logout />} />
              </CustomRoutes>
            </MonthSelectionProvider>
          </SystemUi>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
