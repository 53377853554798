import { formatISO, lastDayOfMonth } from 'date-fns';
import { firstDayOfMonth } from './date';

const formatter = new Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2
});

const plainFormatter = new Intl.NumberFormat('fi-FI', {
  maximumFractionDigits: 2
});

const dateFormatter = new Intl.DateTimeFormat('fi-FI', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour12: false
});

export const formatDate = (value: Date | string): string => {
  if (typeof value === 'string') {
    return dateFormatter.format(Date.parse(value));
  }
  return dateFormatter.format(value);
};

export const formatCurrency = (value: number) => {
  return formatter.format(value);
};

export const formatHours = (value: number) => {
  return plainFormatter.format(value);
};

export const firstDayOfMonthAsISO = (value: Date): string => {
  return formatISO(firstDayOfMonth(value));
};

export const lastDayOfMonthAsISO = (value: Date): string => {
  return formatISO(lastDayOfMonth(value));
};

export const toDecimalString = (value: number, decimals?: number): string => {
  const d = decimals ? decimals : 0;
  return value.toFixed(d).toString();
};

export const monthName = (date: Date | string): string => {
  const d = typeof date === 'string' ? new Date(date) : date;
  return d.toLocaleString('default', { month: 'long' });
};
