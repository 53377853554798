type TagProps = {
  label: string;
  index: number;
  selected: number;
  onClick: (index: number) => void;
};
export const Tag = ({ label, index, selected, onClick }: TagProps) => {
  const bg = selected === index ? 'bg-black text-white' : 'bg-transparent text-black';

  return (
    <div
      className={`flex align-middle ${bg} border-black border pt-1 pb-1 pl-2 pr-2 rounded-full mr-2 cursor-pointer`}
      onClick={() => onClick(index)}
    >
      <span className='mr-3 ml-3'>{label}</span>
    </div>
  );
};
