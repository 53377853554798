export type AvatarProps = {
  url?: string | undefined | null;
  fallback?: string;
  size?: number;
  notification?: boolean;
  className?: string;
  borderColor?: string;
};

export const Avatar = ({
  url,
  fallback,
  size,
  notification,
  className,
  borderColor
}: AvatarProps) => {
  const avatarSize = size != null ? size : 10;

  const adjustedSize = borderColor === undefined ? avatarSize : avatarSize - 1;
  if (url != null) {
    return (
      <span
        className={`flex items-center justify-center self-center sticky
          h-${avatarSize} w-${avatarSize} rounded-full min-h-${avatarSize} min-w-${avatarSize} ${
          borderColor === undefined ? '' : borderColor
        } ${className}`}
      >
        <img
          className={`
            h-${adjustedSize} w-${adjustedSize}
            min-h-${adjustedSize} min-w-${adjustedSize}
            rounded-full`}
          src={url}
          alt=''
          referrerPolicy='no-referrer'
        />
        {notification === true ? (
          <span className='absolute right-0 top-0 block h-2 w-2 rounded-full bg-red-700 ring-2 ring-white' />
        ) : null}
      </span>
    );
  }

  if (fallback != null) {
    return (
      <span
        className={`flex items-center justify-center bg-gray-500 h-${avatarSize} w-${avatarSize} rounded-full sticky min-h-${avatarSize} min-w-${avatarSize} ${
          borderColor === undefined ? '' : borderColor
        }`}
      >
        <span className='text-sm align-baseline font-medium leading-none text-white'>
          {fallback}
          {notification === true ? (
            <span className='absolute right-0 top-0 block h-2 w-2 rounded-full bg-red-700 ring-2 ring-white' />
          ) : null}
        </span>
      </span>
    );
  }

  return (
    <span className='inline-block h-6 w-6 overflow-hidden rounded-full bg-gray-100 sticky'>
      <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
        <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
      </svg>
      {notification === true ? (
        <span className='absolute right-0 top-0 block h-2 w-2 rounded-full bg-red-700 ring-2 ring-white' />
      ) : null}
    </span>
  );
};
