import { formatISO } from 'date-fns';
import { useContext } from 'react';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { Employee, useEmployeeResourcingQuery } from '../../generated/graphql';
import { MonthContext } from '../../providers/MonthContext';
import { DateRange } from '../../utils/date';
import { AssignmentTimeline } from './AssignmentTimeline';
import { UnassignedEmployees } from './UnassignedEmployees';

export const Resourcing = () => {
  const { selectedMonth } = useContext(MonthContext);
  const displayRange: DateRange = DateRange.rangeFrom(selectedMonth, { months: 12 });

  const { data, loading } = useEmployeeResourcingQuery({
    variables: {
      employee_cond: {
        employment_end: { _is_null: true },
        _or: [
          { metadata: { _contains: { non_billable_employee: false } } },
          { _not: { metadata: { _has_key: 'non_billable_employee' } } }
        ]
      },
      assignments_cond: {
        project: { type: { _eq: 'billable' } },
        end_date: {
          _gte: formatISO(displayRange.start, { representation: 'date' })
        }
      }
    }
  });

  const employees: Employee[] = (data?.employee ?? []) as Employee[];
  const employeesWithoutAssignment = employees.filter((e) => e.assignments?.length === 0);

  return (
    <EmptyPageLayout title='Resourcing' loading={loading}>
      <>
        <UnassignedEmployees employees={employeesWithoutAssignment} />
        <AssignmentTimeline employees={employees} displayRange={displayRange} />
      </>
    </EmptyPageLayout>
  );
};
