import { Employee } from '../../generated/graphql';
import { EmployeeAvatar } from './EmployeeAvatar';

interface EmployeeAvatarListProps {
  employees: Employee[];
  size?: number;
  show?: number;
}

export const EmployeeAvatarList = ({ employees, size = 6, show = 4 }: EmployeeAvatarListProps) => {
  const sliced = [...employees].slice(0, show);
  const remainingCount = Math.max(0, employees.length - sliced.length);
  const renderRemaining = remainingCount > 0;
  const cls = `${
    renderRemaining ? 'bg-blue-400' : 'bg-transparent'
  } w-10 h-5 rounded-full mr-3 ml-3 font-bold text-sm text-center ml-3 self-center`;
  return (
    <>
      {sliced && (
        <div className='flex content-center'>
          <div className={cls}>{renderRemaining && <div>+{remainingCount}</div>}</div>
          <div className='isolate flex -space-x-1 overflow-hidden'>
            {sliced.map((employee, index) => (
              <EmployeeAvatar key={index} employee={employee} size={size} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
