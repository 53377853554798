//import { useSortBy, useTable } from 'react-table';
import {
  ColumnOrderState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';

import { useState } from 'react';
import { Spinner } from '../Spinner/Spinner';

interface ExtensibleTableProps {
  columns: any[];
  data: any[];
  loading?: boolean;
  hasFooter?: boolean;
  title?: string;
  action?: JSX.Element | null;
  initialSort?: any;
}

// TODO:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resolve = (path: any, obj: any, separator: string) => {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: any, curr: any) => prev?.[curr], obj);
};

const sortDown = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='w-5 h-5'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25'
    />
  </svg>
);

const sortUp = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    className='w-5 h-5'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12'
    />
  </svg>
);

export const ExtendableTable = ({
  columns,
  data,
  loading = false,
  hasFooter = false,
  title = '',
  action,
  initialSort
}: ExtensibleTableProps) => {
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [sorting, setSorting] = useState<SortingState>(
    initialSort !== undefined ? [initialSort] : []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting
    },
    defaultColumn: {
      minSize: 0,
      size: 0
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const renderCell = (cell: any) => {
    if (cell.getIsPlaceholder()) {
      return null;
    }
    if (cell.getIsAggregated()) {
      return flexRender(
        cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
        cell.getContext()
      );
    }

    return flexRender(cell.column.columnDef.cell, cell.getContext());
  };

  return (
    <>
      {loading ? (
        <div className='flex justify-center mt-10 mb-10'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='relative flex flex-col min-w-0 break-words w-full mb-6 rounded bg-transparent text-slate-700'>
            <div className='rounded-t mb-0 pb-3 border-0'>
              <div className='rounded-t mb-0 px-4 py-3 border-0'>
                <div className='flex flex-wrap items-center'>
                  <div className='relative w-full px-4 max-w-full flex-grow flex-1 '>
                    <h3 className='font-semibold text-2xl '>{title}</h3>
                  </div>
                  {action}
                </div>
              </div>
              <div className='block w-full overflow-visible'>
                <table className='items-center w-full bg-transparent'>
                  <thead className=' text-neutral-50'>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            scope='col'
                            style={{
                              width: header.getSize() !== 0 ? header.getSize() : undefined
                            }}
                            className='px-6 align-middle py-3 text-sm uppercase whitespace-nowrap font-semibold text-left  bg-neutral-900 last:rounded-r-md first:rounded-l-md'
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? 'flex justify-between cursor-pointer select-none'
                                    : 'flex'
                                }
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: sortDown(),
                                  desc: sortUp()
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id} className='group overflow-visible h-14'>
                        {row.getVisibleCells().map((cell, index, arr) => {
                          const isFirst = index === 0;
                          const isLast = index === arr.length - 1;
                          return (
                            <td
                              key={cell.id}
                              style={{
                                width:
                                  cell.column.getSize() !== 0 ? cell.column.getSize() : undefined
                              }}
                              className='relative'
                            >
                              <div
                                className={`absolute inset-0 opacity-0 group-hover:opacity-100 bg-neutral-100 
                                  ${isFirst ? 'group-hover:rounded-l-md' : ''} 
                                  ${isLast ? 'group-hover:rounded-r-md' : ''}`}
                              />
                              <div className='relative px-6 py-3'>{renderCell(cell)}</div>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                  {hasFooter ? (
                    <tfoot>
                      {table.getFooterGroups().map((footerGroup) => (
                        <tr key={footerGroup.id} className='border-0'>
                          {footerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className='px-4 sm:px-6 lg:px-6 text-left font-bold whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 md:pl-0'
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.footer, header.getContext())}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </tfoot>
                  ) : null}
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
