import {
  add,
  endOfMonth,
  endOfYear,
  isSameDay,
  isWeekend,
  startOfMonth,
  startOfYear
} from 'date-fns';

export const workingDaysInMonth = (month: Date, holidays: Date[]): number => {
  return workingDaysBetween(startOfMonth(month), endOfMonth(month), holidays);
};

export const workingDaysBetween = (start: Date, end: Date, holidays: Date[]): number => {
  var wd = 0;

  for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    if (!(isWeekend(d) || datesIncludes(d, holidays))) {
      wd += 1;
    }
  }
  return wd;
};

export const datesIncludes = (item: Date, array: Date[]): boolean => {
  return array.find((d) => isSameDay(d, item)) !== undefined;
};

export const toArray = (start: Date, end: Date): Date[] => {
  const days: Date[] = [];
  for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    days.push(new Date(d));
  }
  return days;
};

export const toMonthArray = (month: Date): Date[] => {
  return toArray(startOfMonth(month), endOfMonth(month));
};

export const firstDayOfMonth = (value: Date): Date => {
  return new Date(value.getFullYear(), value.getMonth(), 1);
};

export type DateRangeConfig = Duration;

export class DateRange implements Iterable<Date> {
  start: Date;
  end: Date;

  constructor(start: Date, end: Date) {
    this.start = start;
    this.end = end;
  }

  *[Symbol.iterator](): IterableIterator<Date> {
    let pos: Date = new Date(this.start);
    while (pos <= this.end) {
      yield pos;
      pos = add(pos, { months: 1 });
    }
  }

  public static rangeFromCurrentYear(): DateRange {
    return new DateRange(startOfYear(Date.now()), endOfYear(Date.now()));
  }

  public static rangeFromCurrentMonth(duration: DateRangeConfig = { months: 12 }): DateRange {
    return new DateRange(new Date(), add(Date.now(), duration));
  }

  public static rangeFrom(date: Date, duration: DateRangeConfig = { months: 12 }): DateRange {
    return new DateRange(startOfMonth(date), add(date, duration));
  }
}
