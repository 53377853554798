import { Assignments, Employee } from '../generated/graphql';

export const sortByEndDate = (assignments: Assignments[]) => {
  if (assignments.length === 0) {
    return assignments;
  }
  // Create a new array to avoid mutating the original
  return [...assignments].sort((a, b) => {
    const da = new Date(a.end_date);
    const db = new Date(b.end_date);
    return da.getTime() - db.getTime();
  });
};

export interface GroupedAssignments {
  employee: Employee;
  assignments: Assignments[];
}

export const groupAssignmentsByEmployee = (assignments: Assignments[]): GroupedAssignments[] => {
  const assignmentMap = assignments.reduce((acc, assignment) => {
    const employeeId = assignment.employee?.id;
    if (!acc.has(employeeId)) {
      acc.set(employeeId, { employee: assignment.employee!, assignments: [] });
    }
    acc.get(employeeId)!.assignments.push(assignment);
    return acc;
  }, new Map<string, { employee: Employee; assignments: Assignments[] }>());

  return Array.from(assignmentMap.values()).map(({ employee, assignments }) => ({
    employee,
    assignments
  }));
};
