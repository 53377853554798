import { createColumnHelper } from '@tanstack/react-table';
import {
  assignmentProjectNameAvatarGenerator,
  currencyColumnGenerator,
  hoursColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Time_Entries } from '../../generated/graphql';
import { groupByProject } from '../../models/TimeEntryTypes';

type EmployeeProjectSummaryProps = {
  entries: Time_Entries[];
};

export const EmployeeProjectSummary = ({ entries }: EmployeeProjectSummaryProps) => {
  const columnHelper = createColumnHelper<Time_Entries>();

  const columns = [
    columnHelper.accessor('assignment', {
      id: 'assignment',
      ...assignmentProjectNameAvatarGenerator()
    }),

    columnHelper.accessor((row) => row.assignment?.external_price, {
      id: 'external_price',
      ...currencyColumnGenerator('External price')
    }),
    columnHelper.accessor((row) => row.assignment?.internal_price, {
      id: 'internal_price',
      ...currencyColumnGenerator('Internal price')
    }),
    columnHelper.accessor((row) => row.hours, {
      id: 'hours',
      ...hoursColumnGenerator('Hours')
    })
  ];

  const groupedEntries = groupByProject(entries);

  return <ExtendableTable columns={columns} data={groupedEntries} title={'Projects'} />;
};
