import { Link } from 'react-router-dom';
import { Employee } from '../../generated/graphql';
import { employeeFullname } from '../../models/EmployeeTypes';

type EmployeeLinkProps = {
  employee: Employee;
  className?: string;
  children?: JSX.Element;
};
export const EmployeeLink = ({ employee, className, children = undefined }: EmployeeLinkProps) => (
  <Link to={`/people/${employee.id}`} className={className}>
    {children ? children : employeeFullname(employee)}
  </Link>
);
