import { Link } from 'react-router-dom';
import { Projects } from '../../generated/graphql';

type ProjectLinkProps = {
  project: Projects;
  className?: string;
  children?: JSX.Element;
};
export const ProjectLink = ({ project, className, children = undefined }: ProjectLinkProps) => (
  <Link to={`/projects/${project.id}`} className={className}>
    {children ? children : project.name}
  </Link>
);
